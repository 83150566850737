export const parseXmlServices = (data, locale) => {
   const localeNode = data.allServicesXml.edges.find((item) => item.node.name == locale.toLowerCase());
    const edges = localeNode.node.xmlChildren;
  return edges.map((node) => {
    var children = node.children;
    var attributes = ["title", "description", "subtitle", "id", "link", "preview", "gallery", "treatments"];
    var result = {};
    attributes.forEach((attribute) => {
      var attributeObject = children.find((item) => item.name == attribute);
      result[attribute] = attributeObject ? attributeObject.content : null;
    });
    return result;
  }); 
};

export const capitalize = (text) => text.replace(/\w/, (c) => c.toUpperCase());

