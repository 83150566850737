const config = {
  languages: ['en', 'it'],
  defaultLanguage: 'it',
  contacts: {
    email: " info@effe2finishing.com",
    emailSecondary: "graphics@effe2finishing.com",
    getformUrl: "https://getform.io/f/128ca32f-4bc5-4a13-abbf-b848d3ee23ad",
    phone: "+39 0435 77150",
    website: "effe2finishing.com",
    address: "Loc. Lagunà - 32040 Vigo di Cadore (BL) ITALY",
    mapUrl: "https://goo.gl/maps/ZvnymZcSuPfkiEQz9",
  }
};

module.exports = config;