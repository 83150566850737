import React from "react";
import Locale from "./Locale.jsx";
import { Link } from "gatsby-plugin-intl";
import logoSrc from "../images/logo.svg";
export default () => {
  var logoStyle = {
    marginTop: "-25px",
  };
  return (
  <div className="container pt-5 pb-4">
    <div className="row justify-content-between">
      <div className="col-4 d-md-flex align-items-end justify-content-start">
        <div className="social-media">
          <p className="mb-0 d-flex">
            <a
              href="https://www.facebook.com/effe2finishing/"
              target="__blank"
              className="d-flex align-items-center justify-content-center"
            >
              <span className="fa fa-facebook">
                <i className="sr-only">Facebook</i>
              </span>
            </a>
          </p>
        </div>
      </div>
      <div className="col-4 d-md-flex align-items-end justify-content-center">
        {" "}
        <Link to="/">
          <img src={logoSrc} width="100" style={logoStyle} />
        </Link>
      </div>
      <div className="col-4 d-md-flex align-items-end justify-content-end">
        <Locale />
      </div>
    </div>
  </div>
)};
