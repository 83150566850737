import React from "react";
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl";
import config from "../utils/config";
import { parseXmlServices } from "../utils";
import { useStaticQuery, graphql } from "gatsby";
import awardSrc from "../images/award.jpg";

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allServicesXml {
        edges {
          node {
            xmlChildren {
              children {
                name
                content
              }
              content
              name
            }
            name
          }
        }
      }
    }
  `);
  const currentLocale = useIntl().locale;
  const services = parseXmlServices(data, currentLocale);
  return (
    <footer className="ftco-footer ftco-bg-dark ftco-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-6 col-lg">
            <div className="ftco-footer-widget mb-4">
              <h2 className="logo text-center">
                <Link className="logo" to="/">
                  <img src={awardSrc} width="130" />
                </Link>
              </h2>
              <p className="text-center">
                <FormattedMessage id="footer.company_info" />
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg">
            <div className="ftco-footer-widget mb-4 ml-md-5">
              <h2 className="ftco-heading-2 capitalize">
                <FormattedMessage id="navbar.services" />
              </h2>
              <ul className="list-unstyled">
                {services.map((service) => (
                  <li key={service.id}>
                    <Link to={service.link} className="py-1 d-block">
                      <span className="ion-ios-arrow-forward mr-3"></span>
                      {service.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2 capitalize">
                <FormattedMessage id="footer.contact_info" />
              </h2>
              <div className="block-23 mb-3">
                <ul>
                  <li key="address">
                    <a href={config.contacts.mapUrl} target="_blank">
                      <span className="text">{config.contacts.address}</span>
                    </a>
                  </li>
                  <li key="phone">
                    <a href={`tel: ${config.contacts.phone}`}>
                      <span className="text">{config.contacts.phone}</span>
                    </a>
                  </li>
                  <li key="email">
                    <a href={`mailto:${config.contacts.email}`}>
                      <span className="text">{config.contacts.email}</span>
                    </a>
                  </li>
                  <li key="vat">
                    <span className="text">
                      <FormattedMessage id="footer.vat" />: 00197750250
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">
                <FormattedMessage id="footer.business_hours" />
              </h2>
              <div className="opening-hours">
                <h4>
                  <FormattedMessage id="footer.opening_days" />:
                </h4>
                <p className="pl-3">
                  <span>
                    <FormattedMessage id="footer.opening_days_info" />
                  </span>
                </p>
                <h4>
                  <FormattedMessage id="footer.vacations" />:
                </h4>
                <p className="pl-3">
                  <span>
                    <FormattedMessage id="footer.all_sundays" />
                  </span>
                  <br></br>
                  <span>
                    <FormattedMessage id="footer.all_holidays" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <small>
              Copyright &copy;
              {new Date().getFullYear()} All rights reserved | This template is
              made with{" "}
              <i className="icon-heart color-danger" aria-hidden="true"></i> by{" "}
              <a href="https://colorlib.com" target="_blank">
                Colorlib
              </a>
              | Developed by{" "}
              <a href="https://www.rhx.it" target="_blank">
                RHX Srl
              </a>
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
};
