import React from "react";
import * as animationStyles from "../styles/modules/animation.module.scss";

export default ({title, children, backgroundSrc}) => {
    const style = {
       backgroundImage: `url('${backgroundSrc}')`,
    //   backgroundPosition: 'center bottom 50px',
    //   backgroundAttachment: 'fixed',
    //   backgroundSize: "100% auto",
      };
    return (
  <section
    className="hero-wrap hero-wrap-2 overlay-section"
    style={style}
    data-stellar-background-ratio="0.5"
  >
    <div className="overlay"></div>
    <div className="container">
      <div className="row no-gutters slider-text align-items-end justify-content-center">
        <div className={`col-md-9 pb-5 text-center ${animationStyles.slider_animation}`}>
          <h1 className="mb-3 bread uppercase">{title}</h1>
          {children}
        </div>
      </div>
    </div>
  </section>
)};
