import React from "react";
import config from "../utils/config";
import { changeLocale, IntlContextConsumer } from "gatsby-plugin-intl";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: config.defaultLanguage,
    };
  }


  render() {
    return (
      <div className="btn-group btn-group-toggle" data-toggle="buttons">
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) =>
            languages.map((language) => (
              <button
                key={language}
                onClick={() => changeLocale(language)}
                title={language}
                className='btn btn-link'
              >
                <img src={require(`../images/flags/${language}.svg`).default} width="20"></img>
              </button>
            ))
          }
        </IntlContextConsumer>
      </div>
    );
  }
}
