import React from "react";
import { FormattedMessage, Link, injectIntl } from "gatsby-plugin-intl";
import Overlay from "./Overlay.jsx";
import contactsImg from "../images/header/contacts_header.jpg";
import servicesImg from "../images/header/services_header.jpg";
import companyImg from "../images/header/company_header.jpg";
import { Helmet } from "react-helmet";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.getCurrentPageTitleId = this.getCurrentPageTitleId.bind(this);
    this.state = {
      pageTitleId: "home",
    };
  }
  getCurrentPageTitleId() {
    var path = this.props.location.pathname;

    var titles = ["services", "company", "contacts"];
    for (const title of titles) {
      if (path.includes(title)) {
        return title;
      }
    }
    return "home";
  }

  render() {
    var style = {
      paddingLeft: "36px",
    };
    var pageTitle = `navbar.${this.getCurrentPageTitleId()}`;
    var headerImages = {
      contacts: contactsImg,
      services: servicesImg,
      company: companyImg,
    };
    return (
      <>
        <nav
          className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
          id="ftco-navbar"
        >
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#ftco-nav"
              aria-controls="ftco-nav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="fa fa-bars"></span> Menu
            </button>
            <div className="collapse navbar-collapse" id="ftco-nav">
              <ul className="navbar-nav m-auto" style={style}>
                <li className="nav-item" key="home">
                  <Link
                    className="nav-link"
                    to="/"
                    activeClassName="text-secondary"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item" key="services">
                  <Link
                    className="nav-link"
                    to="/services"
                    activeClassName="text-secondary"
                    partiallyActive={true}
                  >
                    <FormattedMessage id="navbar.services" />
                  </Link>
                </li>
                <li className="nav-item" key="company">
                  <Link
                    className="nav-link"
                    to="/company"
                    activeClassName="text-secondary"
                  >
                    <FormattedMessage id="navbar.company" />
                  </Link>
                </li>
                <li className="nav-item" key="contacts">
                  <Link
                    className="nav-link"
                    to="/contacts"
                    activeClassName="text-secondary"
                  >
                    <FormattedMessage id="navbar.contacts" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {pageTitle == "navbar.home" ? null : (
          <Overlay
            title={<FormattedMessage id={pageTitle} />}
            backgroundSrc={headerImages[this.getCurrentPageTitleId()]}
          >
            {
              <p className="breadcrumbs">
                <span className="mr-2">
                  <Link to="/">Home</Link>
                </span>{" "}
                <span>
                  <FormattedMessage id={pageTitle} />
                </span>
              </p>
            }
          </Overlay>
        )}
      </>
    );
  }
}

export default injectIntl(Navbar);
