import React from "react";
import { Helmet } from "react-helmet";
import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import Navbar from "./Navbar.jsx";
import { useIntl } from "gatsby-plugin-intl";

export default ({ children, location, helmetSeoParams }) => {
  const getCurrentPageTitleId = function (location) {
    const path = location.pathname
    const titles = ["services", "company", "contacts"]
  
    for (const title of titles) {
      if (path.includes(title)) {
        return title;
      }
    }
    return "home";
  }
  const pageTitleId = getCurrentPageTitleId(location)

  const currentLocale = helmetSeoParams && helmetSeoParams.hasOwnProperty('currentLocale') ? helmetSeoParams.currentLocale : useIntl().locale;
  const alternateUrls = helmetSeoParams && helmetSeoParams.hasOwnProperty('alternateUrls') ? helmetSeoParams.alternateUrls : [];
  const canonicalUrl = helmetSeoParams && helmetSeoParams.hasOwnProperty('canonicalUrl') ? helmetSeoParams.canonicalUrl : "";
  const title = helmetSeoParams && helmetSeoParams.hasOwnProperty('title') ? helmetSeoParams.title : useIntl().formatMessage({
    id: `titles.${pageTitleId}`,
  }) + " - Effe2 s.r.l."
  const description = helmetSeoParams && helmetSeoParams.hasOwnProperty('description') ? helmetSeoParams.description : useIntl().formatMessage({ id: `descriptions.${pageTitleId}` })
  const keywords = helmetSeoParams && helmetSeoParams.hasOwnProperty('keywords') ? helmetSeoParams.keywords : "";

  return (
    <>
      <Helmet>
        <html lang={currentLocale} />
        <meta charSet="utf-8" />
        <title itemProp="name" lang={currentLocale}>{title}</title>
        <link
          href="https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900"
          rel="stylesheet"
        ></link>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        ></link>
        <meta
          id="description"
          name="description"
          content={description}
        />
        <meta name="author" content="RHX Srl - www.rhx.it" />
        {(alternateUrls ? alternateUrls.map((alternateUrl, index) => {
          return <link rel="alternate" key={index} hrefLang={alternateUrl.lang} href={alternateUrl.href} />
        }) : null)}
        {(canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null)}
        {(keywords ? <meta name="keywords" content={keywords} /> : null)}
      </Helmet>
      <Header />
      <Navbar location={location} />
      {children}
      <Footer />
    </>
  )
}
